import { useState } from 'preact/hooks';
import styled from 'styled-components';


import Video from './video';
import Navigation from './navigation';

const StyledApp = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	justify-content: center;

	& > ul {
		position: relative;
		align-self: end;
		z-index: 2;
		grid-column-start: 2;
		grid-row-start: 1;
	}
	
	& > iframe {
		grid-column-start: 2;
		grid-row-start: 1;
		position: relative;
		width: 133vh;
	}
`;

const App = () => {
	const [videoName, setVideoName] = useState('1');

	return (
		<StyledApp>
			<Video fileName={videoName} />
			<Navigation videoName={videoName} onVideoChange={setVideoName} />
		</StyledApp>
	)
}

export default App;
