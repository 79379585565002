import styled from 'styled-components';

const StyledVideo = styled.iframe`
	height: 100vh;
	border: 0;
`
const videoUrlMappings = {
	1: 'https://player.vimeo.com/video/759490081?background=1&h=059afed7fd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	2: 'https://player.vimeo.com/video/777869940?background=1&h=0b5b79ffcb&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	3: 'https://player.vimeo.com/video/759490109?background=1&loop=0&h=ddd1ec5f4d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	4: 'https://player.vimeo.com/video/778599232?background=1&h=94c5d5573b&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	5: 'https://player.vimeo.com/video/778599632?background=1&h=977141a0b8&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	6: 'https://player.vimeo.com/video/759490143?background=1&h=59791de3d8&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	7: 'https://player.vimeo.com/video/759490197?background=1&h=4036dbf894&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'8-1': 'https://player.vimeo.com/video/769254105?background=1&h=0bee397306&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'8-2': 'https://player.vimeo.com/video/759490250?background=1&h=d8d0f086e4&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'8-3': 'https://player.vimeo.com/video/759490284?background=1&h=a9b32f26ec&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'9-1': 'https://player.vimeo.com/video/759490318?background=1&h=6619b410e4&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'9-2': 'https://player.vimeo.com/video/759490337?background=1&h=44e89b88c2&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'10-1': 'https://player.vimeo.com/video/759490373?background=1&h=cd217e1e76&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'10-2': 'https://player.vimeo.com/video/759490389?background=1&h=ea502491be&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'10-3': 'https://player.vimeo.com/video/759490420?background=1&h=c03b084141&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	'10-4': 'https://player.vimeo.com/video/759490447?background=1&h=980f35b6b7&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479',
	11: 'https://player.vimeo.com/video/777870603?background=1&h=4ba40f53ac&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479'
}

const Video = ({fileName}) => {
	return (
		<StyledVideo src={videoUrlMappings[fileName]} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0" />
	)
};

export default Video;
