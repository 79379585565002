import styled from 'styled-components';

const StyledButton = styled.button`
        height: 72px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: url('/assets/icons/${({ videoName }) => videoName}-${({active}) => active ? "active" : "inactive"}.png') no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
        transition: 0.3s;
        padding: 0;
        border: none;

        &:hover {
                background: url('/assets/icons/${({ videoName }) => videoName}-touched.png') no-repeat;
                background-size: contain;
                background-repeat: no-repeat;
        }
        &:focus {
                background: url('/assets/icons/${({ videoName }) => videoName}-active.png') no-repeat;
                background-size: contain;
                background-repeat: no-repeat;
        }
        &.active {
                background: url('/assets/icons/${({ videoName }) => videoName}-active.png') no-repeat;
        }
`;

const Icon = ({videoName, active, onClick}) => {
        return (
                <StyledButton
                        active={active}
                        videoName={videoName}
                        onClick={onClick}
                />
        )
};

export default Icon;
