import Icon from '../icon/index';
import styled from 'styled-components';

const StyledUL = styled.ul`
    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
`

const Nav = styled(StyledUL)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100vh * 1.33);
    margin-bottom: 2%;
    bottom: 0;
    in: auto;
`

const PrimaryIcons = styled(StyledUL)`
    position: relative;
    display: flex;
    justify-content: center;
    gap: 36px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
`

const SecondaryIcons = styled(StyledUL)`
        position: relative;
        display: flex;
        justify-content: center;
        gap: 18px;
        margin-left: ${({ videoName }) => ['8', '8-1', '8-2', '8-3'].includes(videoName) ? '36%' : ['9', '9-1', '9-2'].includes(videoName) ? '44%' : '63%'};
        left: 0;
        right: 0;
        padding: 0;
    `


const Navigation = ({onVideoChange, videoName}) => {

    const onIconClick = (iconName) => {
        ['8', '9', '10'].includes(iconName) ? onVideoChange(`${iconName}-1`) : onVideoChange(iconName);
    }

    const mainIcons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
    const icons = mainIcons.map((iconName) => <li key={iconName} ><Icon videoName={iconName} active={videoName.split("-")[0] == iconName.split("-")[0]} onClick={() => onIconClick(iconName) } /></li>);

    const secondaryIcons = (videoName) => {
        switch (videoName) {
            case '8':
            case '8-1':
            case '8-2':
            case '8-3':
                return ['8-1', '8-2', '8-3'].map((iconName) => <li key={iconName} ><Icon videoName={iconName} active={videoName == iconName} onClick={() => onIconClick(iconName) } /></li>);
            case '9':
            case '9-1':
            case '9-2':
                return ['9-1', '9-2'].map((iconName) => <li key={iconName} ><Icon videoName={iconName} active={videoName == iconName} onClick={() => onIconClick(iconName) } /></li>);
            case '10':
            case '10-1':
            case '10-2':
            case '10-3':
            case '10-4':
                return ['10-1', '10-2', '10-3', '10-4'].map((iconName) => <li key={iconName} ><Icon videoName={iconName} active={videoName == iconName} onClick={() => onIconClick(iconName) } /></li>);
            default:
                return null;
        }
    }
    
    return (
        <Nav>
            <SecondaryIcons videoName={videoName}>
                {secondaryIcons(videoName)}
            </SecondaryIcons>
            <PrimaryIcons>
                {icons}
            </PrimaryIcons>
        </Nav>
    );
};

export default Navigation;
